var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"edit-reservation-payment-settings"},[_c('PageTitle',{attrs:{"title":"預約收款設定","icon":"chevron_left","hideBtn":""},on:{"iconClick":function($event){return _vm.$router.push({ name: 'ReservationParameter' })}}}),_c('ChargeTypeSettingsBlock',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"margin-bottom":"40px"},attrs:{"configData":_vm.configData},on:{"refresh":function($event){_vm.refresh('reservation'), _vm.refresh('deposit')}}}),(_vm.useFeatures.depositConfig || _vm.useFeatures.checkoutConfig)?_c('SectionTitle',{attrs:{"title":"各收款模式設定","hideBtn":""}}):_vm._e(),(_vm.useFeatures.depositConfig || _vm.useFeatures.checkoutConfig)?_c('BasicTabs',{staticClass:"mb-[20px]",attrs:{"tabs":_vm.displayTabs},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}}):_vm._e(),(_vm.useFeatures.depositConfig)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'deposit'),expression:"activeTab === 'deposit'"}]},[_c('DepositSettings',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"configData":_vm.configData.deposit,"payments":_vm.configData.depositPayments},on:{"refresh":function($event){return _vm.refresh('deposit')}}}),(_vm.useFeatures.depositPayments)?_c('PaymentSettingBlock',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"deleteLast":false,"payments":_vm.configData.depositPayments,"updateApi":_vm.UpdateDepositPayment,"createApi":_vm.CreateDepositPayment,"deleteApi":_vm.DeleteDepositPayment,"omitPayments":[
        'atm',
        'pchomepay',
        'offline-jkos',
        'offline-newebpay',
        'offline-linepay',
        'offline-cash',
        'free',
        'offline',
        'COD'
      ]},on:{"refresh":function($event){return _vm.refresh('deposit')}}}):_vm._e()],1):_vm._e(),(_vm.useFeatures.checkoutConfig)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'checkout'),expression:"activeTab === 'checkout'"}]},[_c('ReservationCheckoutSettingsBolck',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"configData":_vm.configData.checkout},on:{"refresh":function($event){return _vm.refresh('checkout')}}}),(_vm.useFeatures.checkoutPayments)?_c('PaymentSettingBlock',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"deleteLast":false,"payments":_vm.configData.checkoutPayments,"updateApi":_vm.UpdateReservationCheckoutPaymentConfig,"createApi":_vm.CreateReservationCheckoutPaymentConfig,"deleteApi":_vm.DeleteReservationCheckoutPaymentConfig,"omitPayments":[
        'atm',
        'pchomepay',
        'offline-jkos',
        'offline-newebpay',
        'offline-linepay',
        'offline-cash',
        'free',
        'offline',
        'COD'
      ]},on:{"refresh":function($event){return _vm.refresh('checkout')}}}):_vm._e(),(_vm.useFeatures.checkoutInvoice)?_c('ReservationCheckoutInvoiceSettingsBlock'):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }