<template>
  <div class="edit-reservation-payment-settings">
    <PageTitle
      title="預約收款設定"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({ name: 'ReservationParameter' })"
    />
    <ChargeTypeSettingsBlock
      v-loading="loading"
      style="margin-bottom: 40px"
      :configData="configData"
      @refresh="refresh('reservation'), refresh('deposit')"
    />

    <SectionTitle
      v-if="useFeatures.depositConfig || useFeatures.checkoutConfig"
      title="各收款模式設定"
      hideBtn
    />

    <BasicTabs
      v-if="useFeatures.depositConfig || useFeatures.checkoutConfig"
      v-model="activeTab"
      class="mb-[20px]"
      :tabs="displayTabs"
    />

    <div v-if="useFeatures.depositConfig" v-show="activeTab === 'deposit'">
      <DepositSettings
        v-loading="loading"
        :configData="configData.deposit"
        :payments="configData.depositPayments"
        @refresh="refresh('deposit')"
      />
      <PaymentSettingBlock
        v-if="useFeatures.depositPayments"
        v-loading="loading"
        :deleteLast="false"
        :payments="configData.depositPayments"
        :updateApi="UpdateDepositPayment"
        :createApi="CreateDepositPayment"
        :deleteApi="DeleteDepositPayment"
        :omitPayments="[
          'atm',
          'pchomepay',
          'offline-jkos',
          'offline-newebpay',
          'offline-linepay',
          'offline-cash',
          'free',
          'offline',
          'COD'
        ]"
        @refresh="refresh('deposit')"
      />
    </div>

    <div v-if="useFeatures.checkoutConfig" v-show="activeTab === 'checkout'">
      <ReservationCheckoutSettingsBolck
        v-loading="loading"
        :configData="configData.checkout"
        @refresh="refresh('checkout')"
      />
      <PaymentSettingBlock
        v-if="useFeatures.checkoutPayments"
        v-loading="loading"
        :deleteLast="false"
        :payments="configData.checkoutPayments"
        :updateApi="UpdateReservationCheckoutPaymentConfig"
        :createApi="CreateReservationCheckoutPaymentConfig"
        :deleteApi="DeleteReservationCheckoutPaymentConfig"
        :omitPayments="[
          'atm',
          'pchomepay',
          'offline-jkos',
          'offline-newebpay',
          'offline-linepay',
          'offline-cash',
          'free',
          'offline',
          'COD'
        ]"
        @refresh="refresh('checkout')"
      />
      <ReservationCheckoutInvoiceSettingsBlock v-if="useFeatures.checkoutInvoice" />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import BasicTabs from '@/components/Tabs/BasicTabs.vue'
import ChargeTypeSettingsBlock from './components/ChargeTypeSettingsBlock.vue'
import DepositSettings from './components/DepositSettings.vue'
import PaymentSettingBlock from '@/components/Payment/PaymentSettingBlock.vue'
import ReservationCheckoutSettingsBolck from './components/ReservationCheckoutSettingsBolck.vue'
import ReservationCheckoutInvoiceSettingsBlock from './components/ReservationCheckoutInvoiceSettingsBlock.vue'
import { useReservation } from '@/use/useReservation'
import { depositPaymentTypes } from '@/config/deposit'
import { UpdateDepositPayment, CreateDepositPayment, DeleteDepositPayment } from '@/api/deposit'
import {
  UpdateReservationCheckoutPaymentConfig,
  CreateReservationCheckoutPaymentConfig,
  DeleteReservationCheckoutPaymentConfig,
} from '@/api/reservation'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'EditReservationPaymentSettings',
  components: {
    BasicTabs,
    ChargeTypeSettingsBlock,
    DepositSettings,
    PaymentSettingBlock,
    ReservationCheckoutSettingsBolck,
    ReservationCheckoutInvoiceSettingsBlock,
  },
  setup (props) {
    const { checkAction } = usePermissions()
    const { getConfig, configData } = useReservation()

    const useFeatures = computed(() => {
      return {
        depositConfig: checkAction('admin.appointmentDepositConfig.page'),
        depositPayments: checkAction('admin.appointmentDepositPaymentConfig.page'),
        checkoutConfig: checkAction('admin.appointmentCheckoutConfig.page'),
        checkoutPayments: checkAction('admin.appointmentCheckoutPaymentConfig.page'),
        checkoutInvoice: checkAction('admin.appointmentCheckoutInvoiceConfig.page'),
      }
    })

    const displayTabs = computed(() => {
      const tabs = []
      if (useFeatures.value.depositConfig) {
        tabs.push({ label: '訂金設定', value: 'deposit' })
      }
      if (useFeatures.value.checkoutConfig) {
        tabs.push({ label: '預約結帳設定', value: 'checkout' })
      }
      return tabs
    })

    const activeTab = ref(displayTabs.value[0].value)
    const loading = ref(false)

    const refresh = async (scope) => {
      if (loading.value) return
      loading.value = true
      if (scope === 'reservation') {
        await getConfig('reservation')
      } else if (scope === 'deposit') {
        await Promise.allSettled([getConfig('deposit'), getConfig('depositPayments')])
      } else if (scope === 'checkout') {
        await Promise.allSettled([getConfig('checkout'), getConfig('checkoutPayments')])
      } else {
        await Promise.allSettled([
          getConfig('reservation'),
          getConfig('depositPayments'),
          getConfig('deposit'),
          getConfig('checkout'),
          getConfig('checkoutPayments'),
        ])
      }
      loading.value = false
    }

    onBeforeMount(async () => {
      refresh()
    })

    return {
      displayTabs,
      activeTab,
      configData,
      depositPaymentTypes,
      getConfig,
      refresh,
      UpdateDepositPayment,
      CreateDepositPayment,
      DeleteDepositPayment,
      UpdateReservationCheckoutPaymentConfig,
      CreateReservationCheckoutPaymentConfig,
      DeleteReservationCheckoutPaymentConfig,
      loading,
      useFeatures,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
